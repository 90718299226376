@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@font-face {
    font-family: Futura PT;
    src: url(./assets/fonts/FuturaPTMedium.otf);
}

* {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    transition: all 0.3s linear;
}

html {
    font-size: 62.5%;
    scroll-padding-top: 9.8rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
}

a {
    text-decoration: none;
}

.container {
    max-width: 1110px !important;
}

.container-fluid {
    max-width: 1440px !important;
}

:root {
    --brown: #ceb992;
    --heading: #22140d;
}

@media (max-width: 991px) {
    html {
        font-size: 55%;
    }
}

@media (max-width: 768px) {
    html {
        font-size: 45%;
    }
}